<template>
    <div class="plot-look">
        <div id="map"></div>

        <div class="bottom-div">
            <div class="top">
                <div class="top-left">
                    <img src="../../assets/images/plot/icon_list_device_plot.png"/>
                    <div class="bottom-content">
                        <p>{{plotObj.massifName}}</p>
                        <p>{{plotObj.parkName}}</p>
                    </div>
                </div>
                <div class="top-right" v-if="!isAdmin">
                    <img v-if="!isEdit" @click="clickEdit" src="../../assets/images/plot/land_edit.png" alt="">
                    <img v-if="isEdit" @click="clickCancle" src="../../assets/images/plot/land_cannel.png" alt="">
                    <img v-if="isEdit" @click="clickSave" src="../../assets/images/plot/land_save.png" alt="">
                </div>
            </div>
            <div class="bottom">
                <div class="bottom-left">
                    <span>地理位置：{{plotObj.address}}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import BaiduMap from '@/components/map/newBaiDuMap';
import { getUserType } from '@/utils/cookie'
import { modifyMassif } from '@/api/plotManagement';
import GPS from '@/utils/coordinate';
export default {
    data() {
        return {
            plotObj: {}, // 地块经纬度集合
            map: null, // 地图实例
            isAdmin: true,//是否是管理员
            isEdit: false,//是否是编辑状态
            polygon: null, // 围栏实例
            editFenceArr: [], // 编辑围栏数组
            lableArr: [], // lable数组
        }
    },
    mounted() {
        window.goAlarm = this.goAlarm;
        this.$nextTick(() => {
            this.isAdmin = getUserType() == 1 ? true : false;
        });
        
        this.plotObj = this.$route.params;
        this.initMap();
    },
    methods: {
        /** 初始化地图 */
        initMap() {
            let option = {
                id: 'map',
                point: {//中心点
                    lng: 116.3975, 
                    lat: 39.9085
                },
            }
            this.map = new BaiduMap();
            setTimeout(() => {
                this.map.initMap(option);
                this.drawFence(); // 画围栏
                this.drawPoint(); // 打地块里的设备点
            }, 500);
        },
        /** 画围栏 */
        drawFence() {
            if (this.plotObj.medication && JSON.parse(this.plotObj.medication).length > 0) {
                let pointarr = JSON.parse(this.plotObj.medication).map((val) => {
                     return this.map.createPoint(val)
                })
                let option = {
                    pointArray:pointarr,//围栏数据（多边形传 数组、 圆形传 单个点）
                    otherConfig:{
                        strokeColor: '#4499FF',
                        strokeWeight: 2,
                        strokeOpacity: 1,
                        fillColor: '#4499FF',
                        fillOpacity: 0.5
                    },//围栏颜色、透明度等相关配置
                };
                this.polygon = this.map.showFence(option); // 方便编辑时清理
                this.map.setViewport({points: pointarr});
            } else if (this.plotObj.describe && !this.plotObj.medication) {  // 没有围栏时打地块位置的点
                let obj = {
                    type: 'custom',
                    iconConfig: {
                        size: new BMap.Size(40,40),//图片大小
                        url:require('../../assets/images/plot/postition.png'), //自定义图片路径
                        otherConfig:{ //其他配置
                            offset:[100,100],
                        }
                    },
                    infoBoxConfig: {
                        content:''
                    },
                    markerConfig: {
                        point: null,
                        eventType:'click', //绑定的事件
                    }
                };
                obj.markerConfig.point = JSON.parse(this.plotObj.describe);
                this.map.createMarker(obj);
                this.map.setViewport({points: [obj.markerConfig.point]});
            }
        },
        /** 打设备点 */
        drawPoint() {
            let item = this.plotObj;
            if (item.deviceList.length > 0) {  // 打地块里的设备点
                item.deviceList.forEach((val, index) => {
                    if (val.lnglat) {
                        let obj = {
                            type: 'eventAndInfobox',
                            iconConfig: {
                                size: new BMap.Size(30,45),//图片大小
                                url:require('../../assets/images/plot/pot.png'), //自定义图片路径
                                otherConfig:{ //其他配置
                                    offset:[100,100],
                                }
                            },
                            infoBoxConfig: {
                                content:''
                            },
                            markerConfig: {
                                point: null,
                                eventType:'click', //绑定的事件
                            }
                        };
                        obj.markerConfig.point = this.map.createPoint(JSON.parse(val.lnglat));
                        obj.infoBoxConfig.content = `<div class="item">
                                                        <div class="top">
                                                            <div class="top-left">
                                                                <img src="${require('../../assets/images/plot/icon_list_device.png')}"/>
                                                                <div class="content">
                                                                    <p>${val.name}</p>
                                                                    <p>IMEI:${val.imei}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="bottom">
                                                            <div class="bottom-left">
                                                                <div class="desc">
                                                                    <span>${val.isLease ? '设备在线' : '设备离线'}</span>
                                                                </div>
                                                            </div>
                                                            <div class="bottom-right">
                                                                <span ontouchend="goAlarm('${val.name}', ${val.imei})">查看报警记录->></span>
                                                            </div>
                                                        </div>
                                                    </div>`;
                        this.map.createMarker(obj);
                    }
                });
            }
        },
        /** 编辑围栏 */
        clickEdit() {
            let _this = this;
            this.isEdit = true;
            this.map.clearMarker(this.polygon);
            //绑定事件
            this.touchstart = function (e) {   // 在手机上地图点击事件不执行
                obj.e = e.changedTouches ? e.changedTouches[0] : e;
                obj.target = e.target;
                obj.time = Date.now();
                obj.X = obj.e.pageX;
                obj.Y = obj.e.pageY;
            }

            this.touchend = function (e) {
                obj.e = e.changedTouches ? e.changedTouches[0] : e;
                if (
                    obj.target === e.target &&
                    
                    // 大于 750 可看成长按了
                    ((Date.now() - obj.time) < 750) &&
                    
                    // 应用勾股定理判断，如果 touchstart 的点到 touchend 的点小于 15，就可当成地图被点击了
                    (Math.sqrt(Math.pow(obj.X - obj.e.pageX, 2) + Math.pow(obj.Y - obj.e.pageY, 2)) < 15)
                ) {
                    // 地图被点击了，执行一些操作
                    _this.clickMap(e);
                }
            }
            // 保存 touch 对象信息
            var obj = {};
        
            this.map.map.addEventListener('touchstart', _this.touchstart);
        
            this.map.map.addEventListener('touchend', _this.touchend);
            // this.map.map.addEventListener('click', _this.clickMap);
        },
        /** 取消编辑围栏 */
        clickCancle() {
            let _this = this;
            this.isEdit = false;
            this.map.clearMarker(this.polygon);
            this.drawFence(); // 画围栏
            this.editFenceArr = [];
            // this.map.map.removeEventListener('click', _this.clickMap);  // 取消地图绑定事件
            this.map.map.removeEventListener('touchstart', _this.touchstart);
            this.map.map.removeEventListener('touchend', _this.touchend);

            this.lableArr.forEach((val) => {  // 循环移除lable
                this.map.clearMarker(val);
            });
            this.lableArr = [];
        },
        /** 保存围栏 */
        clickSave() {
            let _this = this;
            this.isEdit = false;
            this.plotObj.medication = this.editFenceArr.map((val) => {
                return GPS.baiduToGPS(val.lat, val.lng);
            })
            this.plotObj.medication = JSON.stringify(this.plotObj.medication);
            modifyMassif(this.plotObj).then(res => {
                if (res.code == 200) {
                    this.$notify({type: 'success', message: '修改成功'});
                }
            });
            
            this.map.setViewport({points: this.editFenceArr});
            // this.map.map.removeEventListener('click', _this.clickMap);  // 取消地图绑定事件
            this.map.map.removeEventListener('touchstart', _this.touchstart);
            this.map.map.removeEventListener('touchend', _this.touchend);
        },
        /** 地图绑定事件 */
        clickMap(e) {
            this.map.clearMarker(this.polygon);
            this.editFenceArr.push(e.point);

            /** 创建labe */
            let opts = {
                position: e.point,    // 指定文本标注所在的地理位置
                offset: new BMap.Size(-5, -5)    //设置文本偏移量
            };
            let lable = new BMap.Label('',opts);
            lable.setStyle({  //labe样式
                width:'10px',
                height:'10px',
                background: '#4499FF',
                border:'none',
                borderRadius:'50%'
            });
            
            let option = {
                pointArray:this.editFenceArr,//围栏数据（多边形传 数组、 圆形传 单个点）
                otherConfig:{
                    strokeColor: '#4499FF',
                    strokeWeight: 2,
                    strokeOpacity: 1,
                    fillColor: '#4499FF',
                    fillOpacity: 0.5
                },//围栏颜色、透明度等相关配置
            };
            this.polygon = this.map.showFence(option); // 方便编辑时清理
            this.lableArr.push(lable);  // 方便取消时清除

            this.map.map.addOverlay(lable); // 添加lable
        },
        goAlarm(name, imei) {
            console.log(213)
            this.$router.push({name: 'alarm',params: {name: name, imei: imei}});
        }
    },
}
</script>

<style lang="scss" scoped>
.plot-look {
    position: relative;
    #map {
        width: 100%;
        height: 100vh;
        margin: 0;
        padding: 0;
        // background: darkblue;
    }
    .bottom-div {
        position: fixed;
        left: 0;
        right: 0;
        margin: 0 auto;
        bottom: 20px;
        width: 320px;
        // margin-bottom: 10px;
        padding: 12px;
        border-radius: 8px;
        background-color: #fff;
        .top {
            display: flex;
            align-items: flex-end;
            justify-content: space-between;
            .top-left {
                display: flex;
                align-items: center;
                img {
                    width: 38px;
                    height: 38px;
                    margin-right: 12px;
                }
                .bottom-content {
                    p {
                        max-width: 180px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                    p:nth-of-type(1) {
                        font-size: 14px;
                        font-weight: 500;
                        color: #4F4F4F;
                    }
                    p:nth-of-type(2) {
                        margin-top: 8px;
                        font-size: 12px;
                        color: #438EFD;
                    }
                }
            }
            .top-right {
                height: max-content;
                img {
                    width: 26px;
                    height: 26px;
                    margin-left: 10px;
                }
            }
        }
        .bottom {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 10px;
            .bottom-left {
                span {
                    font-size: 12px;
                    color: #8C99A9;
                }
            }
        }
    }
}
</style>
<style lang="scss">
.plot-look {
    .item {
        position: relative;
        width: 250px;
        margin-bottom: 20px;
        padding: 12px;
        border-radius: 8px;
        background-color: #fff;
        font-size: 14px;
        .top {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .top-left {
                display: flex;
                align-items: center;
                img {
                    width: 38px;
                    height: 38px;
                    margin-right: 16px;
                }
                .content {
                    p:nth-child(1) {
                        font-size: 16px;
                        font-weight: 500;
                        color: #4F4F4F;
                    }
                    p:nth-child(2) {
                        font-size: 12px;
                        color: #438EFD;
                    }
                }
            }
        }
        .bottom {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 20px;
            font-size: 12px;
            .bottom-left {
                .desc {
                    span {
                        color: #8C99A9;
                    }
                }
            }
            .bottom-right {
                span {
                    color: #4499FF;
                }
            }
        }
    }
}
</style>