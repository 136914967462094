import GPS from '@/utils/coordinate';
// import emptyIcon from '../../assets/image/map/icon-empty.png';
// import closeIcon from '../../assets/image/map/icon-closeDis.png';
function BaiduMap() {
    this.map = null; //地图对象
    this.openInfo = null;//地图信息框
}
/** 初始化百度地图
 * @param {Object} option 地图配置 (包括中心点、)
 * {
 *      id:'',//地图id
 *      point:'',//地图中心点
 *      zoom:'',//地图缩放层级
 *      scrollZoom:'', //是否开启地图滚动
 *      controlItem:{}//地图控件
 * }
 */
BaiduMap.prototype.initMap = function (option) {
    let { id, point, zoom = 15, scrollZoom = true ,controlItem } = {...option};
    //加载百度地图
    this.map = new BMap.Map(id, {enableMapClick: false,});
    //百度转点
    let tempPoint = GPS.GPSToBaidu(point.lat, point.lng);
    //地图重点坐标
    let centerPoint = new BMap.Point(tempPoint.lng,tempPoint.lat);
    //设置地图操作控件
    controlItem ? this.map.addControl(new BMap.NavigationControl(controlItem)) : '';
    //设置中心点和层级
    this.map.centerAndZoom(centerPoint, zoom); 
    //设置地图是否滚动播放
    scrollZoom ? this.map.enableScrollWheelZoom() : this.map.disableScrollWheelZoom();
};
/**
 * gps点转百度地图坐标点
 * @param {Object} lnglat gps坐标
 * @returns {Object} 转换后的坐标s
*/
BaiduMap.prototype.createPoint = function (lnglat) {
    let { lat,lng} = {...lnglat};
    //得到gps转换后的百度点
    let newPoint = GPS.GPSToBaidu(lat, lng);
    return new BMap.Point(newPoint.lng, newPoint.lat); 
};
/**
 * 设置地图中心点和层级
 * @param {Object} option  包含中心点和层级
 */
BaiduMap.prototype.setCenterAndZoom = function(option) {
    let { point,zoom } = {...option};
    //调用转坐标类型的方法(gps to baidu)
    let newPoint = this.createPoint(point);
    //设置中心点和层级
    this.map.centerAndZoom(newPoint, zoom);
};

/************************与地图覆盖物有关的操作 ********************************/
/** 创建图标
 * @param {Object} option 图标配置
 */
BaiduMap.prototype._createIcon = function (option) {
    let { url, size, otherConfig } = { ...option };
    let icon =  new BMap.Icon(url,size,otherConfig);
    icon.setImageSize(size)
    return icon;
};
/** 创建图标
 * @param {Object} option 图标配置
 *  let config = {
        //标注类型
        type:'eventAndInfobox', 
        //自定义图片配置
        iconConfig:{ 
            size: new BMap.Size(150,150),//图片大小
            url:require('../../assets/location.png'), //自定义图片路径
            otherConfig:{ //其他配置
                offset:[10,10]
            }
        },
        //信息框配置
        infoBoxConfig:{
            content:`<p>${88888}</p>`, //弹框类容
            otherConfig:{}
        },
        //标注配置
        markerConfig:{
            point: this.map.createPoint(this.center), //标注点
            eventType:'click', //绑定的事件
        }
    }
 */

BaiduMap.prototype.createMarker = function(option) {
    let { type = 'default' } = {...option};
    let marker = null;
    // 根据类型判断渲染类型不同的marker
    const createMarkerType = {
        default: this._defaultMarker,//默认marker
        custom: this._customMarker,//自定义图标marker
        eventAndInfobox: this._eventAndInfobox,//带infobox的弹框,可以展示多个
        singleEventAndInfobox: this._singleEventAndInfobox,//带infobox的弹框,只展示一个
    };
    //调用对应逻辑方法  并改变指向 （指向当前对象）
    marker = createMarkerType[type].call(this,option);
    return marker;
};
/**
 * 打默认标注
 * @param {Object} option 点坐标
 */
BaiduMap.prototype._defaultMarker = function(option) {
    //获取坐标数据
    let { markerConfig } = { ...option };
    //创建默认marker
    let defaultMarker = new BMap.Marker(markerConfig.point);
    //添加marker
    this.map.addOverlay(defaultMarker);
    return defaultMarker;
};
/**
 * 自定义图标方法
 * @param {Object} option 自定义图标配置项
 */
BaiduMap.prototype._customMarker = function(option) {
    //获取自动以图标配置以及坐标数据
    let { iconConfig, markerConfig} = {...option};
    //创建marker自定义图标
    let icon = this._createIcon(iconConfig);
    //勇士自定义图标生成marker
    let customMarker = new BMap.Marker(markerConfig.point,{icon: icon});
    //添加marker
    this.map.addOverlay(customMarker);
    return customMarker;
};
/**
 * 可以绑定事件
 * @param {Object} option 点坐标
 */
BaiduMap.prototype._eventAndInfobox = function(option) {
    let that = this;
    //获取配置参数
    let { iconConfig,infoBoxConfig, markerConfig} = {...option};
    //定义图标
    let icon = this._createIcon(iconConfig);
    //创建marker
    // console.log(markerConfig.point,666);
    let eventAndInfoboxMarker = new BMap.Marker(markerConfig.point,{icon: icon});
    let openInfo = null;
    //添加标记点
    that.map.addOverlay(eventAndInfoboxMarker);
    //判断是否自动展开infoBox  status:true 展开 false 不展开  
    let { status= false } = { ...infoBoxConfig}; 
    if( status ) {
        infoBoxConfig.marker = eventAndInfoboxMarker;
        //创建信息框
        openInfo = that.createInfoBox(infoBoxConfig);
    }
    //绑定事件
    eventAndInfoboxMarker.addEventListener(markerConfig.eventType, () => {
        //打开弹框里面的内容
        if(openInfo) {
            //打开infobox
            openInfo.open(eventAndInfoboxMarker);
            //设置位置和内容
            openInfo.setContent(infoBoxConfig.content);
            //设置位置
            openInfo.setPosition(eventAndInfoboxMarker.point);
            infoBoxConfig.content.style.display = 'block';
        }  else {
            //传入marker
            infoBoxConfig.marker = eventAndInfoboxMarker;
            //创建信息框
            openInfo = that.createInfoBox(infoBoxConfig);
        }
    });
    return { marker:eventAndInfoboxMarker, openInfo:openInfo };
};
/**
 * 创建infoBox信息框
 * @param {Object} option 配置项
 */
BaiduMap.prototype.createInfoBox = function(option) {
    let {marker,content,otherConfig} = {...option};
    let openInfo = null;
    openInfo = new BMapLib.InfoBox(this.map, content,otherConfig);
    openInfo.open(marker);
    return openInfo;
};
/**
 * 关闭指定infoBox
 * @param {Object} option 配置项
 */
BaiduMap.prototype.closeInfoBox  = function(option) {
    let {openInfo,infoBoxRef} = {...option};
    openInfo.close();
    infoBoxRef.style.display = 'none';
};
/**
 * 清除marker
 * @param { Object } marker 需要移除的marker
 */
BaiduMap.prototype.clearMarker = function(marker){
    this.map.removeOverlay(marker);
};
/** 画轨迹线
*  @param {Object} option 配置项
 */
BaiduMap.prototype.createPolyline = function (option) {
    let { pointArray, otherConfig} = {...option};
    let polyline = new BMap.Polyline(pointArray,otherConfig);
    this.map.setCenter(pointArray[0]);
    this.map.addOverlay(polyline);
    return polyline;
};
/**
 * 设置轨迹长度
 * @param {Object } option 配置项                                  
 */
BaiduMap.prototype.setPolyline = function (option) {
    let { polyline, points  } = {...option};
    polyline.setPath(points);
};
/**
 * 展示围栏
 * @param {Object} option 配置项目
 * {
 *      type:'circle'|| 'polygon' //覆盖物类型
 *      pointArray:[],//围栏数据（多边形传 数组、 圆形传 单个点）
 *      otherConfig:[],//围栏颜色、透明度等相关配置
 *      radius:'',//type为'circle'时候必填
 *      editStatus:false //是否可以编辑
 *      editCallback:() => {}
 * }
 */
BaiduMap.prototype.showFence = function(option) {
    let { type = 'polygon'  } = { ...option };
    let polygonOrCircle = null;
    const showFenceType = {
        polygon:this._showPolygonFence,
        circle:this._showCircleFence,
        polygonAndInfo:this._showPolygonFenceAndInfoBox,
    };
    polygonOrCircle = showFenceType[type].call(this,option);
    //暂存多边形覆盖物  便于全部清除
    return polygonOrCircle;
};
/**
 * 多边形围栏
 * @param {Object} option 
 */
BaiduMap.prototype._showPolygonFence = function(option) {
    let {pointArray,otherConfig,editStatus = false,editCallback,eventType = 'dblclick'} = {...option};
    let polygon = new BMap.Polygon(pointArray, otherConfig);
    this.map.addOverlay(polygon);
    let that  = this;
    if (editStatus) {
        polygon.enableEditing();
        polygon.addEventListener('lineupdate', () => {
            that.map.addEventListener(eventType, editCallback);     
        });
    }
    return polygon;
};
/**
 * 圆形围栏
 * @param {Object} option 
 */
BaiduMap.prototype._showCircleFence = function(option) {
    let {pointArray,otherConfig,editStatus = false,radius, editCallback,eventType = 'dblclick'} = {...option};
    let circle = new BMap.Circle(pointArray, radius, otherConfig);
    this.map.addOverlay(circle);
    if (editStatus) {
        polygon.enableEditing();
        polygon.addEventListener('lineupdate', () => {
            that.map.addEventListener(eventType, editCallback);     
        });
    }
    return circle;
};
/** 绘制多边形
 * @param {Object} option 多边形围栏配置项
 * {
 *      type:'circle',//绘制多边形的类型   circle圆形 polygon 多边形
 *      otherConfig:{}, //其他相关配置  颜色等
 *      callBack:() => { console.log(8888);} // 绘制完成的回调函数
 * }
 */
BaiduMap.prototype.createFence = function (option) {
    let { type = 'polygon' } = { ...option };
    let polygonOrCircle = null;
    const createFenceType = {
        polygon: this._drewPolygonFence,
        circle: this._drewCircleFence
    };
    //创建百度地图绘制多边形对象
    polygonOrCircle = createFenceType[type].call(this,option);
    //暂存绘制的多边形对象  便于清除
    return polygonOrCircle;
};
/**
 * 绘制多边形围栏
 * @param {Object} option 相关配置
 */
BaiduMap.prototype._drewPolygonFence = function(option) {
    let {otherConfig,callback} = {...option};
    let drawPolygon = new BMapLib.DrawingManager(this.map, otherConfig);
    drawPolygon.setDrawingMode('polygon');
    drawPolygon.addEventListener('overlaycomplete',callback);
    drawPolygon.open();
};
/**
 * 绘制圆形围栏
 * @param {Object} option 相关配置
 */
BaiduMap.prototype._drewCircleFence = function(option) {
    let {otherConfig,callback} = {...option};
    let drawCircle = new BMapLib.DrawingManager(this.map, otherConfig);
    drawCircle.setDrawingMode('circle');
    drawCircle.addEventListener('overlaycomplete', callback);
    drawCircle.open();
};
/** 
 * 放大地图
 */
BaiduMap.prototype.zoomOut = function() {
    let zoom = this.map.getZoom();
    zoom++;
    this.map.setZoom(zoom);
};
/** 
 * 缩小地图
 */
BaiduMap.prototype.zoomIn = function() {
    let zoom = this.map.getZoom();
    zoom--;
    this.map.setZoom(zoom);
};
/** 百度地图添加搜索框
 * @param {String} id 搜索框id
 * {
 *      id:'',//输入框id
 *      cb:() => {} //回调函数
 * }
 */
BaiduMap.prototype.addSearchInput = function(option) {
    let { id,cb } = {...option};
    let that = this;
    let ac = new BMap.Autocomplete(    //建立一个自动完成的对象
        {'input' : id
            ,'location' : map
        });
    ac.addEventListener('onhighlight', function(e) {  
        let str = '';
        let _value = e.fromitem.value;
        let value = '';
        if (e.fromitem.index > -1) {
            value = _value.province +  _value.city +  _value.district +  _value.street +  _value.business;
        }
        str = 'FromItem<br />index = ' + e.fromitem.index + '<br />value = ' + value;
        value = '';
        if (e.toitem.index > -1) {
            _value = e.toitem.value;
            value = _value.province +  _value.city +  _value.district +  _value.street +  _value.business;
        }
        str += '<br />ToItem<br />index = ' + e.toitem.index + '<br />value = ' + value;
        document.getElementById(id).innerHTML = str;
    });
    let myValue = null;
    ac.addEventListener('onconfirm', function(e) {    //鼠标点击下拉列表后的事件
        var _value = e.item.value;
        myValue = _value.province +  _value.city +  _value.district +  _value.street +  _value.business;
        document.getElementById(id).innerHTML ='onconfirm<br />index = ' + e.item.index + '<br />myValue = ' + myValue;
        that._setPlace({value:myValue,cb:cb});
    });
};
/** 百度地图定位 */
BaiduMap.prototype._setPlace = function(option) {
    let { value,cb } = { ...option };
    let that = this;
    this.map.clearOverlays();
    function myFun(){
        var pp = local.getResults().getPoi(0).point;    //获取第一个智能搜索的结果
        that.map.centerAndZoom(pp, 14);
        that.marker = that.createMarker(pp,{icon:{url:positionMarker,size:[40,50],anchor:[0,0]}},null,false,null,null,null,true,true);
        cb({point:pp,search:'search',address:value});
    }
    var local = new BMap.LocalSearch(that.map, { //智能搜索
        onSearchComplete: myFun
    });
    local.search(value);
};
/**
 * 测量距离
 */
BaiduMap.prototype.computeLength = function () {
    let myDis = null;
    myDis = new BMapLib.DistanceTool(this.map, {
        lineColor: '#3270FF',
        secIcon:  new BMap.Icon(emptyIcon, new BMap.Size(12,12)),
        closeIcon: new BMap.Icon(closeIcon, new BMap.Size(12,12)),
    });
    //开始测量
    myDis.open();
};
/**
 * 清空所有覆盖物
 */
BaiduMap.prototype.clearOverlays = function () {
    this.map.clearOverlays();
};
/** 显示所有点的最佳地图层级 
* @param {Object} option 配置项
* array
*/
BaiduMap.prototype.setViewport = function (option) {
    let { points } = { ...option };
    this.map.setViewport(points);
};
/**
 * 添加海量点
 * @param {*} option 配置项
 * let option  = {
    iconUrl:require('@/assets/location.png'),//定位点图标链接 
    pointDataArray:[], //主要点的格式 必传
    clickEvent:() => {},//标注点击事件
    iconWidth:40, //标注宽度
    iconHeight:50, //标注高度
    showDataArray:[]//需要展示的数据
};
 */
BaiduMap.prototype.addPointCollection = function (option) {
    let { iconUrl,pointDataArray, showDataArray, clickEvent, iconWidth = 40,iconHeight = 50, bubbleDom} = { ...option };
    let img = new Image();
    img.src = iconUrl;//自定义点的图片
    let that = this;
    img.onload = function() {
        let newData = [];
        for (var i = 0; i < pointDataArray.length; i++) {
            //海量点数据
            if (pointDataArray[i].point) {
                newData.push({
                    geometry: {
                        type: 'Point',
                        coordinates: [pointDataArray[i].point.lng, pointDataArray[i].point.lat]	//经纬度，用于标点
                    },
                    icon: img,
                    tag:showDataArray[i]
                });
            }
                
        }
        let dataSet = new mapv.DataSet(newData);
        let options = {
            deg:0,
            draw: 'icon',
            methods: {
                //海量点点击事件
                click: (datas) => {
                    let bPoint = new BMap.Point(datas.geometry.coordinates[0], datas.geometry.coordinates[1]);
                    let infoBoxPosition = bPoint;
                    let options = {
                        closeIconUrl: '',
                        offset: new BMap.Size(0, 22),
                    };
                    if(!that.openInfo) {
                        that.openInfo = new BMapLib.InfoBox(that.map,bubbleDom, options);
                    }
                    that.openInfo.open(bPoint);
                    that.openInfo.enableAutoPan(); // 信息框自动平移到看完
                    clickEvent(datas,that.openInfo,infoBoxPosition);
                } 
            },
            width: iconWidth,
            height: iconHeight,
            size:40
        };
        //显示海量点
        let mapvLayer = new mapv.baiduMapLayer(that.map, dataSet, options);
    };
};

export default BaiduMap;